import React from 'react';
import Icons from "./Icons";

export default class Events extends React.Component {

	render() {
		return (
			<div className='content'>
				{/*<div className='column'>*/}
				{/*	<h1>2024 Events</h1>*/}

				{/*	<div className='content'>*/}
				{/*		/!*<div className='row'>*!/*/}
				{/*		/!*	<div className='column'>*!/*/}
				{/*		/!*		<h2>{Icons.RALLY} Ranger Rally </h2>*!/*/}
				{/*		/!*		<p>*!/*/}
				{/*		/!*			Usually the third Saturday of a month, the Ranger Rally is our*!/*/}
				{/*		/!*			quarterly club "flight" gathering. This is a great opportunity*!/*/}
				{/*		/!*			to meet other members, show off your flying skills, and*!/*/}
				{/*		/!*			learn from others. Our usual location is:*!/*/}
				{/*		/!*		</p>*!/*/}
				{/*		/!*		<p className='content'>*!/*/}
				{/*		/!*			Wardle Fields Regional Park<br/>*!/*/}
				{/*		/!*			14148 S 2700 W, Bluffdale, UT 84065*!/*/}
				{/*		/!*		</p>*!/*/}
				{/*		/!*		<h3 className='complete'>Feb 17 @ 09:00am - Ranger Rally</h3>*!/*/}
				{/*		/!*		<h3 className='complete'>May 18 @ 09:00am - Ranger Rally</h3>*!/*/}
				{/*		/!*		<h3 className='complete'>Aug 17 @ 09:00am - Ranger Rally</h3>*!/*/}
				{/*		/!*		<h3>Oct 19 @ 09:00am</h3>*!/*/}

				{/*		/!*	</div>*!/*/}
				{/*		/!*	/!*<div className='column'>*!/*!/*/}
				{/*		/!*	/!*	<h2>{Icons.CLUB} Ranger Rendezvous</h2>*!/*!/*/}
				{/*		/!*	/!*	<p>*!/*!/*/}
				{/*		/!*	/!*		Usually the third Saturday of a month, the Ranger*!/*!/*/}
				{/*		/!*	/!*		Rendezvous is our monthly club "non-flight" gathering. This*!/*!/*/}
				{/*		/!*	/!*		is a great opportunity to meet other members, discuss*!/*!/*/}
				{/*		/!*	/!*		club business, and plan future events. Our usual location is:*!/*!/*/}
				{/*		/!*	/!*	</p>*!/*!/*/}
				{/*		/!*	/!*	<p className='content'>*!/*!/*/}
				{/*		/!*	/!*		Soderquist Residence<br/>*!/*!/*/}
				{/*		/!*	/!*		13626 Bluewing Way, Riverton, UT 84096*!/*!/*/}
				{/*		/!*	/!*	</p>*!/*!/*/}

				{/*		/!*	/!*	<h3>Sep 21 @ 10:00am</h3>*!/*!/*/}
				{/*		/!*	/!*	<h3>Oct 16 @ 10:00am</h3>*!/*!/*/}
				{/*		/!*	/!*	<h3>Dec 14 @ 10:00am</h3>*!/*!/*/}
				{/*		/!*	/!*</div>*!/*!/*/}
				{/*		/!*</div>*!/*/}
				{/*	</div>*/}
				{/*</div>*/}

				<div className="row">

					<div className='column'>
						<h1>2025 Events</h1>

						<div className='content'>
							<div className='row'>
								<div className='column'>
									<h2>{Icons.RALLY} Ranger Rally </h2>
									<p>
										Usually the third Saturday of a month, the Ranger Rally is our
										quarterly club "flight" gathering. This is a great opportunity
										to meet other members, show off your flying skills, and
										learn from others. Our usual location is:
									</p>
									<p className='content'>
										Wardle Fields Regional Park<br/>
										14148 S 2700 W, Bluffdale, UT 84065
									</p>
									<h3>Feb 15 @ 09:00am</h3>
									<h3>May 17 @ 09:00am</h3>
									<h3>Aug 16 @ 09:00am</h3>
									<h3>Oct 25 @ 09:00am</h3>

								</div>
								{/*<div className='column'>*/}
								{/*	<h2>{Icons.CLUB} Ranger Rendezvous</h2>*/}
								{/*	<p>*/}
								{/*		Usually the third Saturday of a month, the Ranger*/}
								{/*		Rendezvous is our monthly club "non-flight" gathering. This*/}
								{/*		is a great opportunity to meet other members, discuss*/}
								{/*		club business, and plan future events. Our usual location is:*/}
								{/*	</p>*/}
								{/*	<p className='content'>*/}
								{/*		Soderquist Residence<br/>*/}
								{/*		13626 Bluewing Way, Riverton, UT 84096*/}
								{/*	</p>*/}

								{/*	<h3>Jan 18 @ 10:00am</h3>*/}
								{/*	<h3>Mar 15 @ 10:00am</h3>*/}
								{/*	<h3>Apr 19 @ 10:00am</h3>*/}
								{/*	<h3>Jun 21 @ 10:00am</h3>*/}
								{/*	<h3>Jul 19 @ 10:00am</h3>*/}
								{/*	<h3>Sep 20 @ 10:00am</h3>*/}
								{/*	<h3>Nov 15 @ 10:00am</h3>*/}
								{/*	<h3>Dec 13 @ 10:00am</h3>*/}
								{/*</div>*/}
							</div>
						</div>
					</div>
				</div>

			</div>
		)
	}

}
